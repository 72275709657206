import loadable from '@loadable/component';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import * as UI from '../../ui';
const ClientsLogos = loadable(() => import('../ClientsLogos'));

const { Title, DynamicContainer, ContainerType } = UI;

const ClientsSection = ({ styles, rules }) => (
    <DynamicContainer columns={12} type={ContainerType.FLEX} extend={{ container: rules.centeredContainer }}>
        <ClientsLogos />
    </DynamicContainer>
);

ClientsSection.propTypes = {
    styles: PropTypes.shape().isRequired,
    rules: PropTypes.shape({
        centeredContainer: PropTypes.func.isRequired,
        title: PropTypes.func.isRequired,
    }).isRequired,
};

export default ClientsSection;
